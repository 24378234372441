import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';
import type { PaginationPosition, PaginationTheme } from './types';

interface Props {
  className?: string;
  position: PaginationPosition;
  verticalMarginFromEdge: number;
  horizontalMarginFromEdge: number;
  paginationTheme: PaginationTheme;
}

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'left' | 'right';

export const CarouselPagination = React.memo(
  ({
    position,
    className,
    verticalMarginFromEdge,
    horizontalMarginFromEdge,
    paginationTheme,
  }: Props) => {
    const paginationStyle = useMemo(() => {
      const [verticalPosition, horizontalPosition] = position.split('-') as [
        VerticalPosition,
        HorizontalPosition | undefined,
      ];

      const style: CSSProperties = {};

      style[verticalPosition] = verticalMarginFromEdge + 'px';

      if (horizontalPosition !== undefined) {
        style['left'] = horizontalPosition === 'left' ? horizontalMarginFromEdge + 'px' : 'auto';
        style['right'] = horizontalPosition === 'right' ? horizontalMarginFromEdge + 'px' : 'auto';
        style['transform'] = 'none';
      }

      return style;
    }, [horizontalMarginFromEdge, position, verticalMarginFromEdge]);

    return (
      <div
        className={classNames(
          'swiper-pagination',
          `swiper-pagination--${paginationTheme}`,
          className,
        )}
        style={paginationStyle}
      />
    );
  },
);

CarouselPagination.displayName = 'CarouselPagination';
